import React, {useState} from "react";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {useCookies} from "react-cookie";
import api from "../Api";


const LoginPage = () => {
    const useStyles = makeStyles(styles)
    const classes = useStyles()
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function () {
        setCardAnimation("");
    }, 700);

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [loginFailed, setLoginFailed] = useState(false)

    const handleFailedLogin = () => {
        setLoginFailed(true)
        setTimeout(() => {
            setLoginFailed(false)
        }, 3000)
    }

    const [cookies, setCookie] = useCookies(['main_refresh_token', 'main_token']);

    const writeJwtToCookies = (accessToken, refreshToken) => {
        console.log('Writing jwt cookies', accessToken, refreshToken)
        setCookie("main_token", accessToken, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        setCookie("main_refresh_token", refreshToken, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
    }

    const handleLogin = () => {
        api.loginToServer(
            username,
            password,
            (d) => {
                handleFailedLogin()
                console.log("Login failed %", d)
            },
            (d) => {
                console.log(d)
                setLoginFailed(false)
                writeJwtToCookies(d.access_token, d.refresh_token)
                document.location.href = "/admin/index.html"
            },
        )
    }

    const handleKeyPress = (target) => {
        let e = target.nativeEvent
        if (e.charCode === 13 || e.code === 'Enter' || e.code === 'NumpadEnter')
            handleLogin()
    }

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="rose"
                            >
                                <h4 className={classes.cardTitle}>Log in</h4>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Username..."
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon}/>
                                            </InputAdornment>
                                        ),
                                        value: username,
                                        onChange: event => {
                                            setUsername(event.target.value)
                                        },
                                        onKeyDown: target => {
                                            handleKeyPress(target)
                                        }
                                    }}
                                />
                                <CustomInput
                                    labelText="Password"
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}>
                                                    lock_outline
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: "password",
                                        autoComplete: "off",
                                        value: password,
                                        onChange: event => {
                                            setPassword(event.target.value)
                                        },
                                        onKeyDown: target => {
                                            handleKeyPress(target)
                                        }
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button color="rose" simple size="lg" block onClick={e => handleLogin()}>
                                    Let{"'"}s Go
                                </Button>
                                {loginFailed &&
                                <SweetAlert
                                    warning
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                    onConfirm={() => setLoginFailed(false)}
                                    style={{display: "block", marginTop: "-100px"}}
                                    title="Login failed!"
                                />
                                }
                            </CardFooter>
                        </Card>

                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default LoginPage;

