// Library packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Material-ui components
import { Tooltip, Button as MuiButton, IconButton as MuiIconButton } from '@material-ui/core';

// Styling
import withStyles from '@material-ui/core/styles/withStyles';
import { colors, colorsList, colorsEnum, tooltipPlacementsList, tooltipPlacementsEnum } from '@collabra/cway-frontend-common/constants';
import styles from './styles';

const Button = ({
  classes, children, customClasses, ref, tooltip, tooltipPlacement, bgColor, contentColor, disabled, onClick, justIcon,
  fullWidth, roundedCorners, active, noSiblingsMargin,
}) => {
  const Component = justIcon ? MuiIconButton : MuiButton;

  const wrapperClasses = cn({
    [classes.wrapper]: true,
    [classes.wrapperSpaced]: !noSiblingsMargin,
    [classes.wrapperFullWidth]: fullWidth && !justIcon,   // only text button can take full width
  });
  const buttonClasses = cn(classes.button, {
    [classes[bgColor]]: bgColor,
    [classes.iconButton]: justIcon,
    [classes.textButton]: !justIcon,
    // TODO: replace this usage with customClasses.active
    [classes.active]: active && justIcon,                 // only icon button can be active
    [classes.roundedCorners]: roundedCorners && !justIcon,  // only text button can have rounded angles
  });
  const buttonCustomClasses = cn({
    [customClasses.root]: customClasses.root,
    [customClasses.active]: customClasses.active && active,
  });

  const contentColorStyle = contentColor ? { color: colors[contentColor] } : null;

  return (
    <Tooltip title={tooltip} placement={tooltipPlacement} disableFocusListener>
      { /* Div is needed for ability to provide disabled child to MaterialUI Tooltip */ }
      <div className={wrapperClasses}>
        <Component
          buttonRef={ref}
          className={cn(buttonClasses, buttonCustomClasses)}
          style={contentColorStyle}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </Component>
      </div>
    </Tooltip>
  );
};

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,

  customClasses: PropTypes.object,
  ref: PropTypes.node,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(tooltipPlacementsList),
  bgColor: PropTypes.oneOf(colorsList),
  contentColor: PropTypes.oneOf(colorsList),
  disabled: PropTypes.bool,
  noSiblingsMargin: PropTypes.bool,
  onClick: PropTypes.func.isRequired,

  justIcon: PropTypes.bool,
  fullWidth: PropTypes.bool,            // not applicable if justIcon=TRUE
  roundedCorners: PropTypes.bool,       // not applicable if justIcon=TRUE
  active: PropTypes.bool,               // not applicable if justIcon=FALSE
};

Button.defaultProps = {
  customClasses: {},
  ref: null,
  tooltip: '',                          // tooltip is disabled by default
  tooltipPlacement: tooltipPlacementsEnum.bottom,
  bgColor: colorsEnum.transparent,
  contentColor: null,
  disabled: false,
  noSiblingsMargin: false,

  justIcon: false,
  fullWidth: false,
  roundedCorners: false,
  active: false,
};

export default withStyles(styles)(Button);
