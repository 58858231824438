import React from 'react';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import StorageList from "./StorageList";

const StoragePage = () => {
    return (<GridContainer>
        <GridItem sm={1} />
        <GridItem sm={10}>
            <StorageList />
        </GridItem>
    </GridContainer>)

}

export default StoragePage

