// Packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Material-ui components
import { CircularProgress } from '@material-ui/core';

// Constants
import { colors } from '@collabra/cway-frontend-common/constants';

// Styling
import withStyles from '@material-ui/core/styles/withStyles';
const styles = {
  background: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundFullscreen: {
    zIndex: 10000,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  icon: {
    color: colors.gray,
  },
};

const LoadingIndicator = ({ classes, fullscreen }) => (
  <div className={cn(classes.background, { [classes.backgroundFullscreen]: fullscreen })}>
    <CircularProgress className={classes.icon} />
  </div>
);

LoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  fullscreen: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  fullscreen: false,
};

export default withStyles(styles)(LoadingIndicator);
