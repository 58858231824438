/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from '@material-ui/core/styles';
const styles = {
  root: {
    height: 25,
    width: '100%',
  },
  input: {
    fontSize: 12,
    display: 'flex',
    padding: 0,
    height: 24,
    border: '1px solid black',
    backgroundColor: 'white',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
};
const useStyles = makeStyles(styles);

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control = ({ selectProps, innerRef, children, innerProps }) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: selectProps.classes.input,
        inputRef: innerRef,
        children,
        ...innerProps,
      },
    }}
    {...selectProps.textFieldProps}
  />
);

const Option = ({ innerRef, isFocused, isSelected, innerProps, children }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      height: '10px !important',
      padding: '2px 3px',
      fontSize: 12,
      fontWeight: isSelected ? 500 : 400,
    }}
    {...innerProps}
  >
    {children}
  </MenuItem>
);

const ValueContainer = ({ selectProps, children }) => <div className={selectProps.classes.valueContainer}>{children}</div>;

const Menu = ({ selectProps, innerProps, children }) => <Paper square className={selectProps.classes.paper} {...innerProps}>{children}</Paper>;

const components = {
  Option,
  Control,
  ValueContainer,
  Menu,
};

const Autocomplete = ({ suggestions, selectedValue, onChange }) => {
  const classes = useStyles();

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: 'black',
    }),
    placeholder: (provided) => ({
      ...provided,
      position: 'absolute',
      left: 2,
      fontSize: 12,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      backgrounColor: 'red',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: '0 3px',
      fontSize: 12,
      whiteSpace: 'nowrap',
      width: 'calc(100% - 40px)',
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          isClearable
          isSearchable
          classes={classes}
          styles={selectStyles}
          options={suggestions}
          components={components}
          value={selectedValue}
          onChange={onChange}
          placeholder="username"
          noOptionsMessage={() => 'No matching users'}
        />
      </NoSsr>
    </div>
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.array.isRequired,
  selectedValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Autocomplete;
