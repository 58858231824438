// Packages
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Material UI components
import { Tabs, Tab } from '@material-ui/core';

// Constants
import { colors } from '@collabra/cway-frontend-common/constants';

// Styling
import { makeStyles } from '@material-ui/core/styles';
const borderRadiusLg = 5;
const inputsBgColor = '#ffffff';
const styles = {
  root: {
    flex: 1,
    width: '100%',
    minWidth: 400,
    borderRadius: borderRadiusLg,
    display: 'flex',
    flexDirection: 'column',
  },
  tabsRoot: {
    position: 'relative',
    top: borderRadiusLg,
    minHeight: 'unset',
  },
  tabsIndicator: {
    width: 0,
    height: 0,
  },
  tabRoot: {
    minWidth: 'unset',
    minHeight: 'unset',
    overflow: 'unset',
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
  },
  tabRootDisabled: {
    cursor: 'default',
  },
  tabSelected: {
    border: `1px solid ${colors.gray}`,
    borderTopLeftRadius: borderRadiusLg,
    borderTopRightRadius: borderRadiusLg,
    borderBottom: 'none',
    backgroundColor: inputsBgColor,

    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: borderRadiusLg - 1,
      bottom: 0,
      backgroundColor: '#ffffff',
      right: -1,
      left: 0,
    },
    '&:not(:first-of-type)': {
      '&:before': {
        left: -1,
      },
    },
  },

  tabContainer: {
    flex: 1,
    borderRadius: borderRadiusLg,
    border: `1px solid ${colors.gray}`,
    overflow: 'hidden',
  },
  textarea: {
    resize: 'none',
    width: '100%',
    height: '100%',
    border: 'none',
    padding: 10,
    backgroundColor: '#ffffff',   // to keep bg color when textarea is disabled
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
};
const useStyles = makeStyles(styles);

const Textarea = ({ editedValue, onChange, previewedValue, previewDisabled, cursorPosition }) => {
  const classes = useStyles();

  // ---------- Active tab control --------------------

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // ---------- Preview in iframe --------------------

  const iframeDOMNode = useRef(null);

  useEffect(() => {
    if (activeTabIndex === 1) {
      const iframe = iframeDOMNode.current.contentWindow || iframeDOMNode.current.contentDocument.document || iframeDOMNode.current.contentDocument;
      iframe.document.open();
      iframe.document.write(previewedValue);
      iframe.document.close();
    }
  }, [activeTabIndex, previewedValue]);

  // ---------- Set cursor inside Mustache tag after auto-close --------------------

  const textareaDOMNode = useRef();     // DOM node to set cursor into desired position
  const cursorPositionPrev = useRef();  // previous cursor position

  useEffect(() => {
    // If cursor is located in empty Mustache tag after delete first closing brace - keep cursor position
    if ((cursorPosition !== null) && (cursorPosition === cursorPositionPrev.current)) {
      textareaDOMNode.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  });

  useEffect(() => {
    // Set cursor into Mustache tag after auto-close
    if (cursorPosition !== null) {
      textareaDOMNode.current.setSelectionRange(cursorPosition, cursorPosition);
    }
    cursorPositionPrev.current = cursorPosition;
  }, [cursorPosition]);

  // --------------------------------------------------------------------------------

  return (
    <div className={classes.root}>
      <Tabs
        value={activeTabIndex}
        onChange={previewDisabled ? null : (event, indexValue) => setActiveTabIndex(indexValue)}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        <Tab
          disableRipple
          label="Write"
          classes={{ root: cn(classes.tabRoot, { [classes.tabRootDisabled]: previewDisabled }), selected: classes.tabSelected }}
        />
        <Tab
          disableRipple
          label="Preview"
          classes={{ root: cn(classes.tabRoot, { [classes.tabRootDisabled]: previewDisabled }), selected: classes.tabSelected }}
        />
      </Tabs>

      {(activeTabIndex === 0) && (
        <div className={classes.tabContainer}>
          <textarea
            ref={textareaDOMNode}
            className={classes.textarea}
            value={editedValue}
            onChange={(event) => onChange(event.target.value, textareaDOMNode.current.selectionStart)}
            disabled={previewDisabled}
          />
        </div>
      )}

      {(activeTabIndex === 1) && (
        <div className={classes.tabContainer}>
          <iframe className={classes.iframe} ref={iframeDOMNode} />
        </div>
      )}
    </div>
  );
};

Textarea.propTypes = {
  editedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  previewedValue: PropTypes.string,
  previewDisabled: PropTypes.bool.isRequired,
  cursorPosition: PropTypes.any,
};
Textarea.defaultProps = {
  editedValue: '',
  previewedValue: '',
  cursorPosition: null,
};

export default Textarea;
