import axios from 'axios'
import qs from 'qs';

// Basic auth for the graphql server
const basicAuth = {
    auth: {
        username: 'EoYCm7LFks0aSI5RCMiNjCvOEehG5EEC',
        password: 'YaFAERl0fJX2Pfl7Qd6m'
    }
}

export default class Api {

    static loginToServer(username, password, onError, onDone) {
        axios.post(`${process.env.PUBLIC_URL}/oauth/token`,
            qs.stringify({grant_type: 'admin', username: username, password: password}),
            {...basicAuth})
            .then((response) => {
                let data = response.data
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                onDone && onDone(data)
            })
            .catch((error) => {
                console.log(`Got error ${error}`)
                onError && onError(error.message)
                console.log(onError)
            })
    }

    static selectOrganisation(orgId, jwtRefreshToken, onDone) {
        axios.post(`${process.env.PUBLIC_URL}/oauth/token`,
          qs.stringify({grant_type: 'refresh_token', refresh_token: jwtRefreshToken, organisation_id: orgId}),
            {...basicAuth}
        )
            .then((response) => {
                if (response.status === 200 && response.data && response.data.access_token) {
                    console.log(response);
                    const data = response.data;
                    // Store cookies
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                    onDone && onDone(data)
                }
            })
            .catch(() => {

            });
    }

}
