import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Styling
import { makeStyles } from '@material-ui/core/styles';
const styles = {
  textarea: {
    border: 'none',
    borderRadius: 10,
    resize: 'none',
    height: '100%',
    padding: 10,
    backgroundColor: '#ffffff',   // to keep bg color when textarea is disabled
  },
};
const useStyles = makeStyles(styles);

const MailTemplatesEditor = ({ data, onChange, disabled, cursorPosition }) => {
  const classes = useStyles();

  // ---------- Set cursor inside Mustache tag after auto-close --------------------

  const textareaDOMNode = useRef();     // DOM node to set cursor into desired position
  const cursorPositionPrev = useRef();  // previous cursor position

  useEffect(() => {
    // If cursor is located in empty Mustache tag after delete first closing brace - keep cursor position
    if ((cursorPosition !== null) && (cursorPosition === cursorPositionPrev.current)) {
      textareaDOMNode.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  });

  useEffect(() => {
    // Set cursor into Mustache tag after auto-close
    if (cursorPosition !== null) {
      textareaDOMNode.current.setSelectionRange(cursorPosition, cursorPosition);
    }
    cursorPositionPrev.current = cursorPosition;
  }, [cursorPosition]);

  // --------------------------------------------------------------------------------

  return (
    <textarea
      ref={textareaDOMNode}
      className={classes.textarea}
      value={data}
      onChange={(event) => onChange(event.target.value, textareaDOMNode.current.selectionStart)}
      disabled={disabled}
    />
  );
};

MailTemplatesEditor.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  cursorPosition: PropTypes.any,
};
MailTemplatesEditor.defaultProps = {
  data: '',
  cursorPosition: null,
};

export default MailTemplatesEditor;
