// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';

// Child components
import Table from '../Table/Table.js';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CardBody from '../Card/CardBody';
import Info from '../Typography/Info';
import UsersToolBar from './UsersToolBar';
import SelectBox from './SelectBox';

// Icons
import { faCheckCircle, faCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BusinessTwoTone } from '@material-ui/icons';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
const styles = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};
const useStyles = makeStyles(styles);

// GraphQL queries
const ORGANISATION_AND_PERMISSIONS = gql`
    query Organisation($orgId: UUID!) {
        organisation(id: $orgId) {
            id
            name
            users {
                id
                username
                name
                organisationMemberships {
                    organisationId
                    name
                    permissionGroupId
                }
            }
        }
        getPermissionGroups {
            id
            name
        }
    }
`;

const Users = ({ organisation, showOrgs }) => {
  const classes = useStyles();

  // ---------- Get users list for organisation --------------------

  const { loading, error, data, refetch } = useQuery(ORGANISATION_AND_PERMISSIONS, { variables: { orgId: organisation.id }, fetchPolicy: 'network-only' });
  const allUsersOfOrg = data?.organisation.users;
  const allPermissionGroups = data?.getPermissionGroups || [];

  // ---------- Select users --------------------

  const [selectedUsers, setSelectedUsers] = useState([]);

  const selectRow = (user) => {
    if (!selectedUsers.includes(user)) setSelectedUsers(selectedUsers.concat(user));
  };
  const deselectRow = (user) => {
    if (selectedUsers.includes(user)) setSelectedUsers(selectedUsers.filter((item) => item !== user));
  };
  const deselectAll = () => setSelectedUsers([]);
  const selectAll = () => setSelectedUsers(allUsersOfOrg);

  // ---------- Data for table --------------------

  const tableData = () => {
    let rows = [];
    if (allUsersOfOrg) {
      rows = allUsersOfOrg.map((user) => {
        const permissionGroupId = user.organisationMemberships?.find(({ organisationId }) => organisationId === organisation.id)?.permissionGroupId;
        const permissionGroup = allPermissionGroups.find((pg) => pg.id === permissionGroupId);
        const permissionGroupName = permissionGroup ? permissionGroup.name : 'UNKNOWN GROUP';
        return ([
          <SelectBox item={user} selectedItems={selectedUsers} select={selectRow} deselect={deselectRow} />,
          user.username,
          user.name,
          permissionGroupName,
        ]);
      });
    }
    return rows;
  };

  // ---------- GraphQL error --------------------

  if (error) return (<p>Error :(</p>);

  // --------------------------------------------------------------------------------

  return (
    <div>
      <UsersToolBar
        organisation={data?.organisation}
        selectedUsernames={selectedUsers.map(({ username }) => username)}
        deselectAll={() => setSelectedUsers([])}
        allPermissionGroups={allPermissionGroups}
        showOrgs={showOrgs}
        reloadOrg={refetch}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose"><BusinessTwoTone /></CardIcon>
              <h4 className={classes.cardIconTitle}>
                {organisation.name} &gt; users
              </h4>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  selectedUsers.length > 0
                    ? <FontAwesomeIcon icon={faCheckCircle} onClick={deselectAll} />
                    : <FontAwesomeIcon icon={faCircle} onClick={selectAll} />,
                  'Username',
                  'Name',
                  'Permissions',
                ]}
                tableData={tableData()}
                coloredColls={[3]}
                colorsColls={['primary', 'info', 'info']}
              />
              {loading && <Info><FontAwesomeIcon icon={faSpinner} spin /> <strong>Loading..</strong></Info>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

Users.propTypes = {
  organisation: PropTypes.object.isRequired,
  showOrgs: PropTypes.func.isRequired,
};

export default Users;
