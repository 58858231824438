import React, {useState} from 'react';
import {useMutation, useQuery, gql} from "@apollo/client";
import Badge from "../Badge/Badge";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones, faSpinner} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(styles);

const PermissionGroupField = ({username, permissionGroupId, organisationId}) => {

    const classes = useStyles();

    const [editing, setEditing] = useState(false)

    const [permissionGroupIdToSet, setPermissionGroupIdToSet] = useState(permissionGroupId)

    const editMode = () => {
        setEditing(true)
    }

    const GET_PERMISSIONGROUPS = gql`
        query GetPermissionGroups{
            getPermissionGroups{
                id
                name
            }
        }
    `
    const SET_PERMISSION_GROUP = gql`
        mutation SetPermissionGroup($usernames: [String!]!, $permissionGroupId: UUID!, $organisationId: UUID!){
            setPermissionGroupForUsers(usernames: $usernames, organisationId: $organisationId, permissionGroupId: $permissionGroupId) {
                id
                organisationMemberships {
                    organisationId
                    name
                    permissionGroupId
                }
            }
        }
    `
    const {loading: permissionsLoading, error: permissionsError, data: permissionsData} = useQuery(GET_PERMISSIONGROUPS)

    const [setPermissionGroup, {error: setPermissionGroupError}] = useMutation(SET_PERMISSION_GROUP);

    if(permissionsError || setPermissionGroupError)
        return <Badge color="rose"><FontAwesomeIcon icon={faSkullCrossbones} size="lg"/></Badge>

    if (permissionsLoading)
        return <Badge color="rose"><FontAwesomeIcon icon={faSpinner} spin={true}/></Badge>

    const permissionGroups = () => {
        if (permissionsData && permissionsData.getPermissionGroups)
            return permissionsData.getPermissionGroups.reduce(function (map, group) {
                map[group.id] = group
                return map
            }, {})
        return {}
    }
    if (!editing)
        return <Button onClick={editMode}><Badge
            color="rose">{permissionGroups()[permissionGroupId] ? permissionGroups()[permissionGroupId].name : permissionGroupId}</Badge></Button>


    const menuItems = () => {
        return permissionsData.getPermissionGroups.map(permissionGroup =>
             <MenuItem
                classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }}
                value={permissionGroup.id}
            >
                 {permissionGroup.name}
            </MenuItem>
        )
    }

    const handleSave = event => {
        const newGroupId = event.target.value
        setPermissionGroupIdToSet(newGroupId)
        setPermissionGroup({ variables: { usernames: [username], organisationId: organisationId, permissionGroupId: newGroupId } })
    }

    return <FormControl
        fullWidth
        className={classes.selectFormControl}>
        <InputLabel
            htmlFor="multiple-select"
            className={classes.selectLabel}
        >
            Choose group
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu
            }}
            classes={{
                select: classes.select
            }}
            value={permissionGroupIdToSet}
            onChange={handleSave}
            inputProps={{
                name: "simpleSelect",
                id: "simple-select"
            }}
        >
            <MenuItem
                disabled
                classes={{
                    root: classes.selectMenuItem
                }}
            >
                Choose permission group
            </MenuItem>

            {menuItems()}
        </Select>
    </FormControl>
}


export default PermissionGroupField
