import React from "react";

import {useCookies} from "react-cookie";
import {Redirect} from "react-router-dom";


const LoginPage = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['main_refresh_token', 'main_token']);

    removeCookie(['main_refresh_token', 'main_token'])
    console.log('Clearing jwt cookies')
    removeCookie("main_token", {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
    removeCookie("main_refresh_token", {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})

    return (
        <Redirect to={"/admin/auth/login"}/>
    );
}

export default LoginPage;
