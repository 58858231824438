// Packages
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {gql, useMutation} from '@apollo/client';

// Child components
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../CustomButtons/Button.js';
import ChangePermissionDialog from './UsersPermissionChangeDialog';
import UserInviteDialog from './UserInviteDialog';

// GraphQL requests
const DELETE_USERS = gql`
    mutation deleteUsers($organisationId: UUID, $usernames: [String!]!){
        deleteUsers(organisationId: $organisationId, usernames: $usernames)
    }
`;

const UsersToolBar = ({organisation, selectedUsernames, deselectAll, allPermissionGroups, showOrgs, reloadOrg}) => {
    const [deleteUsers] = useMutation(DELETE_USERS);
    const handleDeleteUsers = async () => {
        await deleteUsers({variables: {usernames: selectedUsernames, organisationId: organisation.id}});
        reloadOrg();
    };

    const approveForOrderingFunc = async (approved) => {
        for (const username of selectedUsernames) {
            await approveForOrdering({
                variables: {
                    username: username,
                    organisationId: organisation.id,
                    approvedForOrdering: approved
                }
            });
        }
    };

    const handleApproveForOrdering = () => approveForOrderingFunc(true);
    const handleRemoveApproveForOrdering = () => approveForOrderingFunc(false);


    const [showInviteModal, setShowInviteModal] = useState(false);
    const handleShowInviteModal = () => setShowInviteModal(true);
    const handleCloseInviteModal = () => setShowInviteModal(false);

    const [showPermissionsModal, setShowPermissionsModal] = useState(false);
    const handleShowPermissionsModal = () => setShowPermissionsModal(true);
    const handleClosePermissionsModal = () => {
        setShowPermissionsModal(false);
        deselectAll();
    };

    const orgNotLoadedYet = !organisation;
    const noUsersSelected = selectedUsernames.length === 0;

    return (
        <GridContainer>
            {showInviteModal &&
                <UserInviteDialog organisation={organisation} handleClose={handleCloseInviteModal}
                                  reloadOrg={reloadOrg}/>}
            {showPermissionsModal && (
                <ChangePermissionDialog
                    organisation={organisation}
                    usernames={selectedUsernames}
                    allPermissionGroups={allPermissionGroups}
                    handleClose={handleClosePermissionsModal}
                    reloadOrg={reloadOrg}
                />
            )}

            <GridItem xs={12} sm={12} md={12}>
                <Button onClick={showOrgs} color="success">Back to organisations list</Button>
                <Button disabled={orgNotLoadedYet} onClick={handleShowInviteModal} color="danger">+ Invite</Button>
                <Button disabled={orgNotLoadedYet || noUsersSelected} onClick={handleDeleteUsers}
                        color="success">Remove</Button>
                <Button disabled={orgNotLoadedYet || noUsersSelected} onClick={handleApproveForOrdering}
                        color="success">Approve orderer</Button>
                <Button disabled={orgNotLoadedYet || noUsersSelected} onClick={handleRemoveApproveForOrdering}
                        color="success">Remove orderer approval</Button>
                <Button disabled={orgNotLoadedYet || noUsersSelected} onClick={handleShowPermissionsModal}
                        color="danger">Change permissions</Button>
            </GridItem>
        </GridContainer>
    );
};

UsersToolBar.propTypes = {
    organisation: PropTypes.object,
    selectedUsernames: PropTypes.arrayOf(PropTypes.string).isRequired,
    deselectAll: PropTypes.func.isRequired,
    allPermissionGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    showOrgs: PropTypes.func.isRequired,
    reloadOrg: PropTypes.func.isRequired,
};
UsersToolBar.defaultProps = {
    organisation: null,
};

export default UsersToolBar;
