import React from 'react';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CustomInput from "../CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Search} from "@material-ui/icons";

const FilterBox = ({filterUsername}) => {
    return <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <CustomInput
                labelText="Search"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    endAdornment: (<InputAdornment position="end"><Search/></InputAdornment>),
                    onChange: event => {
                        filterUsername(event.target.value)
                    }
                }}
            />
        </GridItem>
    </GridContainer>
}

export default FilterBox