// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';

// Child components
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '../CustomButtons/Button';
import Slide from '@material-ui/core/Slide';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Icons
import Close from '@material-ui/icons/Close';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import modalStyles from '../../assets/jss/material-dashboard-pro-react/modalStyle.js';
const styles = {
  ...modalStyles(),
  formControl: {
    margin: 5,
    minWidth: 120,
  },
};
const useStyles = makeStyles(styles);

// GraphQL requests
const SET_PERMISSION_GROUP = gql`
    mutation setPermissionGroup($organisationId: UUID!, $permissionGroupId: UUID!, $usernames: [String!]!){
        setPermissionGroupForUsers(organisationId: $organisationId, permissionGroupId: $permissionGroupId, usernames: $usernames) {
            id
            organisationMemberships {
                organisationId
                name
                permissionGroupId
            }
        }
    }
`;

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const UsersPermissionChangeDialog = ({ usernames, organisation, allPermissionGroups, handleClose, reloadOrg }) => {
  const classes = useStyles();

  const [selectedPermissionGroupId, setSelectedPermissionGroupId] = useState('');

  const [setPermissionGroupForUsers] = useMutation(SET_PERMISSION_GROUP);
  const handleSetPermissionGroup = () => {
    setPermissionGroupForUsers({ variables: {
      organisationId: organisation.id, permissionGroupId: selectedPermissionGroupId, usernames,
    } });
    reloadOrg();
    handleClose();
  };

  return (
    <Dialog
      classes={{ root: classes.center, paper: classes.modal }}
      open
      transition={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
        <Button justIcon className={classes.modalCloseButton} color="transparent" onClick={handleClose}>
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Change permission</h4>
      </DialogTitle>

      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Choose group</InputLabel>
              <Select
                labelid="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPermissionGroupId}
                onChange={(event) => setSelectedPermissionGroupId(event.target.value)}
              >
                {allPermissionGroups.map((pg) => <MenuItem key={pg.id} value={pg.id}>{pg.name}</MenuItem>)}
              </Select>
            </FormControl>

          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Button onClick={handleSetPermissionGroup} disabled={selectedPermissionGroupId === ''} color="success">Save</Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

UsersPermissionChangeDialog.propTypes = {
  usernames: PropTypes.arrayOf(PropTypes.string).isRequired,
  organisation: PropTypes.object.isRequired,
  allPermissionGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadOrg: PropTypes.func.isRequired,
};

export default UsersPermissionChangeDialog;
