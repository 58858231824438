import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard} from "@fortawesome/pro-regular-svg-icons";
import React from "react";

const CopyPopup = ({show}) => {
    return (
        <div style={{
            position: "fixed",
            top: '50%',
            left: '50%',
            marginTop: '-75px',
            marginLeft: '-75px',
            width: '150px',
            height: '150px',
            backgroundColor: 'rgba(0,0,0,0.6)',
            borderColor: 'rgba(0,0,0,0.6)',
            borderRadius: '75px',
            zIndex: 10000000,
            textAlign: "center",
            color: "#fff",
        }}>
            <div style={{
                marginTop: "25px",
            }}>
                <FontAwesomeIcon icon={faClipboard} size={"5x"}/>
            </div>
            <div style={{
                marginTop: "5px",
                fontWeight: "900",
            }}>
                ID COPIED!
            </div>
        </div>
    );
};

export default CopyPopup
