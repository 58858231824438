import React, {useState} from 'react';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../CustomButtons/Button.js';
import {gql, useMutation} from '@apollo/client';

const DEACTIVATE_MUTATION = gql`
    mutation Deactivate($organisationIds: [UUID!]!){
        deactivateOrganisations(organisationIds: $organisationIds)
    }
`;
const ACTIVATE_MUTATION = gql`
    mutation Activate($organisationIds: [UUID!]!){
        activateOrganisations(organisationIds: $organisationIds)
    }
`;

const OrganisationsToolBar = ({selectedOrganisations, deselectAll}) => {
    const [showMergeModal, setShowMergeModal] = useState(false);

    const [deactivateOrganisations] = useMutation(DEACTIVATE_MUTATION);
    const [activateOrganisations] = useMutation(ACTIVATE_MUTATION);

    const handleDeactivate = () => {
        deactivateOrganisations({variables: {organisationIds: selectedOrganisations.map((org) => org.id)}});
        console.log('Deactivate: ', selectedOrganisations.map((org) => org.id));
        deselectAll();
    };
    const handleActivate = () => {
        activateOrganisations({variables: {organisationIds: selectedOrganisations.map((org) => org.id)}});
        console.log('Activate: ', selectedOrganisations.map((org) => org.id));
        deselectAll();
    };
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Button disabled={selectedOrganisations.length === 0} onClick={handleDeactivate} color="danger">
                    Deactivate({selectedOrganisations.length})
                </Button>
                <Button disabled={selectedOrganisations.length === 0} onClick={handleActivate} color="success">
                    Activate({selectedOrganisations.length})
                </Button>
            </GridItem>
        </GridContainer>
    );
};

export default OrganisationsToolBar;
