// Packages
import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import Mustache from 'mustache';
import { gql, useQuery, useMutation } from '@apollo/client';

// Constants
import { colors, colorsEnum } from '@collabra/cway-frontend-common/constants';
import { emailTemplatesContextTags } from '../constants';

// Helpers
import { getEnvironment } from '@collabra/cway-frontend-common/utils';
import { sortObjectsArray, codeToNodesTree, nodesTreeToCode, updateTemplateWithMustacheAutoclose } from '../utils';

// Icons
import { EyeIcon, PlusIcon } from '@collabra/cway-frontend-common/icons';

// Child components
import LoadingIndicator from '../newComponents/LoadingIndicator';
import Button from '../newComponents/Button';
// import Autocomplete from '../newComponents/Autocomplete';
import MailTemplatesEditor from '../newComponents/MailTemplatesEditor';

// Styling
import { makeStyles } from '@material-ui/core/styles';
const styles = {
  root: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',     // for abs-positioned children
  },
  panels: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
  },
  panel: {
    flex: 1,
    minWidth: 0,
    border: '2px solid #999999',
    borderRadius: 10,
    padding: 10,
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginRight: 10,
    },
  },
  leftPanel: {
    flex: '0 0 350px',
    backgroundColor: '#cccccc',
  },
  searchField: {
    border: 'none',
    backgroundColor: colors.white,
    marginBottom: 10,
    padding: '5px 10px',
    color: colors.text,
  },
  list: {
    flex: 1,
    minHeight: 0,
    borderRadius: 10,
    backgroundColor: colors.white,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'auto',
  },
  centerPanel: {
    padding: 0,
  },
  dropdownContainer: {
    flex: 0,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    zIndex: 1,            // to keep dropped list above all other elements
  },
  title: {
    fontSize: 16,
  },
  dropdownTitle: {
    marginRight: 10,
  },
  editorWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  rightPanel: {
    flex: '0 0 300px',
    backgroundColor: '#cccccc',
  },
  rightSubPanel: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  rightSubPanelTop: {
    marginBottom: 15,
  },
  rightSubPanelTitleAndButton: {
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightSubPanelTitle: {
    color: colors.white,
  },

  buttonsContainer: {
    marginTop: 20,
    width: 430,
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },

  tag: {
    cursor: 'pointer',
  },
  tagSelected: {
    fontWeight: 'bold',
  },
  tagIcon: {
    color: colors.success,
    marginRight: 5,
  },
  tagName: {},

  tagPopup: {
    zIndex: 1,      // to be above dropdown input
    position: 'absolute',
    right: 300,
    top: 20,
    width: 400,
    height: 500,
    // minWidth: 200,
    // maxWidth: 500,
    // minHeight: 200,
    // maxHeight: 600,
    border: '2px solid #999999',
    borderRadius: 10,
    backgroundColor: '#cccccc',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    color: colors.white,
  },
  tagPopupTitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  tagPopupSubtitle: {
    fontSize: 12,
    marginBottom: 2,
  },
  tagPopupNameContainer: {
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  tagPopupNameInput: {
    border: '2px solid #999999',
    padding: 5,
  },
  tagPopupContent: {
    flex: 1,
    overflow: 'auto',
    border: '2px solid #999999',
    borderRadius: 10,
    backgroundColor: colors.white,
    padding: 5,
    color: colors.text,
    fontSize: 12,
  },
  textarea: {
    width: '100%',
    height: 'calc(100% - 10px)',
    resize: 'none',
    border: 'none',
  },
  tagPopupButtonsContainer: {
    marginTop: 10,
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },

  createSnippetButtonWrapper: {   // this class is only assigned to detect clicks on button wrapper (in pageClick)
    margin: 0,
  },
};
const useStyles = makeStyles(styles);

const MESSAGE_TEMPLATES = gql`
    query MessageTemplates {
        messageTemplates {
            id
            name
            body
            subject
            wrapper
        }
    }
`;
const UPDATE_TEMPLATE = gql`
    mutation ($input: MessageTemplateInput) {
        updateTemplate(input: $input){
            id
            name
            body
            subject
            wrapper
        }
    }
`;
const MESSAGE_SNIPPETS = gql`
    query MessageSnippets {
        messageSnippets {
            name
            snippet
        }
    }
`;
const CREATE_SNIPPET = gql`
    mutation ($input: MessageSnippetInput) {
        createSnippet(input: $input){
            name
            snippet
        }
    }
`;
const UPDATE_SNIPPET = gql`
    mutation ($input: MessageSnippetInput) {
        updateSnippet(input: $input){
            name
            snippet
        }
    }
`;
const DELETE_SNIPPET = gql`
    mutation ($name: String!) {
        deleteSnippet(name: $name)
    }
`;

const MailTemplates = () => {
  console.group('MailTemplates()');
  const classes = useStyles();

  // ---------- Show and hide tag popup --------------------

  const [displayedTag, setDisplayedTag] = useState({ name: null, snippet: null, new: null });

  // If click is on tag in list or tag popup - do nothing. If click is anywhere else in page - hide popup.
  const pageClick = (event) => {
    let currentElement = event.target;
    let clickOnTag = false;
    let clickOnTagPopup = false;
    let clickOnCreateSnippetButton = false;
    do {
      clickOnTag = currentElement.classList.contains(classes.tag);
      clickOnTagPopup = currentElement.classList.contains(classes.tagPopup);
      clickOnCreateSnippetButton = currentElement.classList.contains(classes.createSnippetButtonWrapper);
      if (clickOnTag || clickOnTagPopup || clickOnCreateSnippetButton) {
        break;
      }
      currentElement = currentElement.parentElement;
    } while (currentElement.parentElement);
    if (!clickOnTag && !clickOnTagPopup && !clickOnCreateSnippetButton) {
      setDisplayedTag({ name: null, snippet: null, new: null });
      document.removeEventListener('click', pageClick);
    }
  };

  // ---------- Templates --------------------

  const [selectedTemplate, setSelectedTemplate] = useState({ id: null, name: null, body: null, subject: null, wrapper: null });

  const { loading: templatesLoading, data: templatesData } = useQuery(MESSAGE_TEMPLATES);
  const wrapper = !templatesData ? {} : templatesData.messageTemplates.find((template) => template.wrapper);
  const sortedTemplates = !templatesData ? [] : [...templatesData.messageTemplates].sort(sortObjectsArray('name'));

  const templateIsSelected = (selectedTemplate.id !== null) && (selectedTemplate.name !== null) && (selectedTemplate.body !== null);
  const selectedTemplateIsChanged = templateIsSelected && (sortedTemplates.find((t) => t.id === selectedTemplate.id).body !== selectedTemplate.body);

  const [updateTemplate, { loading: updateTemplateInProcess }] = useMutation(UPDATE_TEMPLATE, {
    variables: { input: {
      id: selectedTemplate.id, name: selectedTemplate.name, body: selectedTemplate.body, subject: selectedTemplate.subject,
    } },
    refetchQueries: [{ query: MESSAGE_TEMPLATES }],
  });

  // If this is not NULL - Textarea will set cursor in Mustache tag after autoclose
  const [cursorPosition, setCursorPosition] = useState(null);

  // ---------- Search in templates list --------------------

  const [searchString, setSearchString] = useState('');
  const filteredTemplates = (searchString === '') ? sortedTemplates : sortedTemplates.filter(
    (template) => template.name.toLowerCase().includes(searchString.toLowerCase()),
  );

  // ---------- Snippets (used as Mustache partials) --------------------

  const { loading: snippetsLoading, data: snippetsData } = useQuery(MESSAGE_SNIPPETS);
  const snippets = !snippetsData ? [] : snippetsData.messageSnippets;

  let selectedSnippetIsChanged = false;
  let discardSnippetChanges = null;
  const snippetIsSelected = (displayedTag.name !== null) && (displayedTag.snippet !== null);
  if (snippetIsSelected && !displayedTag.new) {
    console.log('snippetIsSelected: ', snippetIsSelected);
    const backendSnippet = snippets.find((s) => s.name === displayedTag.name).snippet;
    console.log('backendSnippet: ', backendSnippet);
    // Format backend snippet before compare because formatted snippet is stored in state
    const formattedBackendSnippet = nodesTreeToCode(codeToNodesTree(backendSnippet));
    console.log('formattedBackendSnippet: ', formattedBackendSnippet);
    selectedSnippetIsChanged = snippetIsSelected && (formattedBackendSnippet !== displayedTag.snippet);
    console.log('selectedSnippetIsChanged: ', selectedSnippetIsChanged);

    discardSnippetChanges = () => setDisplayedTag((prevValue) => ({ ...prevValue, snippet: formattedBackendSnippet }));
  }

  const [createSnippet, { loading: createSnippetInProcess }] = useMutation(CREATE_SNIPPET, {
    variables: { input: { name: displayedTag.name, snippet: displayedTag.snippet } },
    refetchQueries: [{ query: MESSAGE_SNIPPETS }],
  });
  const [updateSnippet, { loading: updateSnippetInProcess }] = useMutation(UPDATE_SNIPPET, {
    variables: { input: { name: displayedTag.name, snippet: displayedTag.snippet } },
    refetchQueries: [{ query: MESSAGE_SNIPPETS }],
  });
  const [deleteSnippet, { loading: deleteSnippetInProcess }] = useMutation(DELETE_SNIPPET, {
    variables: { name: displayedTag.name },
    refetchQueries: [{ query: MESSAGE_SNIPPETS }],
  });

  // ---------- Set height of snippet textarea depending on its content height --------------------

  // const textareaDOMNode = useRef(null);
  // const autoGrowTextarea = () => {
  //   if (textareaDOMNode.current) {
  //     textareaDOMNode.current.style.height = '5px';
  //     textareaDOMNode.current.style.height = `${textareaDOMNode.current.scrollHeight}px`;
  //   }
  // };

  // ---------- Preview --------------------

  const previewWindowSelected = useRef(null);
  const previewWindowAll = useRef(null);

  const isPreviewWindowOpen = (windowInstance) => windowInstance && !windowInstance.closed;

  // Create HTML preview for one template or all templates: if editableTemplate passed - preview for one template, if not passed - for all templates.
  const renderHtmlPreview = (editableTemplate) => {
    let fullTemplateBody = '';
    if (editableTemplate) {
      // Show only passed template
      fullTemplateBody = editableTemplate.wrapper ? editableTemplate.body : wrapper.body.replace('{{BODY_CONTENT}}', editableTemplate.body);
    } else {
      // Join all templates (except wrapper) and put each of them in separate bordered box
      const templateBoxStyle = 'border: 1px solid #CFE2FE; border-radius: 5px; padding: 5px; margin: -15px;';
      const allTemplatesWithNames = sortedTemplates.reduce((wholeString, currentTemplate) => {
        // Skip wrapper
        if (currentTemplate.wrapper) return wholeString;
        // For selected template use its currently edited version (to keep unsaved changes)
        const pastedTemplate = (currentTemplate.id === selectedTemplate.id) ? selectedTemplate : currentTemplate;
        return `${wholeString}<h4>${pastedTemplate.name}</h4><div style="${templateBoxStyle}">${pastedTemplate.body}</div><br/>`;
      }, '');
      fullTemplateBody = wrapper.body.replace('{{BODY_CONTENT}}', allTemplatesWithNames);
    }
    const snippetsObject = snippets.reduce((obj, { name, snippet }) => ({ ...obj, [name]: snippet }), {});
    if (displayedTag.name) {
      snippetsObject[displayedTag.name] = displayedTag.snippet;
    }
    return Mustache.render(fullTemplateBody, emailTemplatesContextTags, snippetsObject);
  };

  // Open/focus one template preview window: if the window is not shown - show it, if it's already shown - focus it.
  const showPreviewSelected = () => {
    if (!isPreviewWindowOpen(previewWindowSelected.current)) {
      previewWindowSelected.current = window.open('', '_blank', `noreferer,left=0,top=0,width=${700},height=${window.innerHeight}`);
      previewWindowSelected.current.document.write(renderHtmlPreview(selectedTemplate));
      previewWindowSelected.current.document.title = `Preview template: ${selectedTemplate.name} (${getEnvironment()})`;
    } else {
      previewWindowSelected.current.focus();
    }
  };
  // Open/focus all templates preview window: if the window is not shown - show it, if it's already shown - focus it.
  const showPreviewAll = () => {
    if (!isPreviewWindowOpen(previewWindowAll.current)) {
      previewWindowAll.current = window.open('', '_blank', `noreferer,left=${700},top=0,width=${900},height=${window.innerHeight}`);
      previewWindowAll.current.document.write(renderHtmlPreview());
      previewWindowAll.current.document.title = `Preview all mail templates (${getEnvironment()})`;
    } else {
      previewWindowAll.current.focus();
    }
  };

  // Update preview window on template or snippet edit
  useEffect(() => {
    if (isPreviewWindowOpen(previewWindowSelected.current)) {
      previewWindowSelected.current.document.close();
      previewWindowSelected.current.document.write(renderHtmlPreview(selectedTemplate));
      previewWindowSelected.current.document.title = `Preview template: ${selectedTemplate.name} (${getEnvironment()})`;
    }
    if (isPreviewWindowOpen(previewWindowAll.current)) {
      previewWindowAll.current.document.close();
      previewWindowAll.current.document.write(renderHtmlPreview());
      previewWindowAll.current.document.title = `Preview all mail templates (${getEnvironment()})`;
    }
  }, [selectedTemplate, displayedTag]);

  // Close opened preview windows on component unmount
  useEffect(() => () => {
    if (isPreviewWindowOpen(previewWindowSelected.current)) previewWindowSelected.current.close();
    if (isPreviewWindowOpen(previewWindowAll.current)) previewWindowAll.current.close();
  }, []);

  // ---------- GraphQL loading or error indication --------------------

  // TODO: implement error handling
  const gqlRunning = (
    templatesLoading || snippetsLoading || createSnippetInProcess || updateSnippetInProcess
    || deleteSnippetInProcess || updateTemplateInProcess
  );

  // --------------------------------------------------

  console.groupEnd();

  return (
    <div className={classes.root}>
      {gqlRunning && <LoadingIndicator fullscreen />}

      <div className={classes.panels}>
        <div className={cn(classes.panel, classes.leftPanel)}>
          <input
            type="search"
            className={classes.searchField}
            value={searchString}
            onChange={(event) => setSearchString(event.target.value)}
            placeholder="Type here to search..."
          />

          <div className={classes.list}>
            {filteredTemplates.map((template) => (
              <div
                className={cn(classes.tag, { [classes.tagSelected]: template.id === selectedTemplate.id })}
                key={template.id}
                onClick={() => setSelectedTemplate(filteredTemplates.find((t) => t.id === template.id))}
              >
                <span className={classes.tagName}>{template.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className={cn(classes.panel, classes.centerPanel)}>
          {/* <div className={classes.dropdownContainer}> */}
          {/*  <span className={cn(classes.title, classes.dropdownTitle)}>Template</span> */}
          {/*  <Autocomplete */}
          {/*    suggestions={sortedTemplates.map((t) => ({ value: t.id, label: t.name }))} */}
          {/*    selectedValue={{ value: selectedTemplate.id, label: selectedTemplate.name }} */}
          {/*    onChange={({ value }) => setSelectedTemplate(sortedTemplates.find((t) => t.id === value))} */}
          {/*  /> */}
          {/* </div> */}

          <div className={classes.editorWrapper}>
            <MailTemplatesEditor
              data={selectedTemplate.body || ''}
              onChange={(updatedData, cursorPos) => {
                updateTemplateWithMustacheAutoclose(updatedData, cursorPos, setSelectedTemplate, setCursorPosition);
              }}
              cursorPosition={cursorPosition}
              disabled={!templateIsSelected}
            />
          </div>
        </div>

        <div className={cn(classes.panel, classes.rightPanel)}>
          <div className={cn(classes.rightSubPanel, classes.rightSubPanelTop)}>
            <span className={cn(classes.title, classes.rightSubPanelTitle)}>Content</span>
            <div className={classes.list}>
              {Object.keys(emailTemplatesContextTags).map((tagKey) => (
                <div
                  className={cn(classes.tag, { [classes.tagSelected]: tagKey === displayedTag.name })}
                  key={tagKey}
                  onClick={() => {
                    setDisplayedTag({ name: tagKey, snippet: null, new: false });
                    document.addEventListener('click', pageClick, { capture: true });
                  }}
                >
                  <EyeIcon className={classes.tagIcon} />
                  <span className={classes.tagName}>{tagKey}</span>
                </div>
              ))}
            </div>
          </div>

          <div className={classes.rightSubPanel}>
            <div className={classes.rightSubPanelTitleAndButton}>
              <span className={cn(classes.title, classes.rightSubPanelTitle)}>Snippets</span>
              <div className={classes.createSnippetButtonWrapper}>
                <Button
                  justIcon
                  contentColor={colorsEnum.success}
                  tooltip="Create snippet"
                  onClick={() => {
                    setDisplayedTag({ name: '', snippet: '', new: true });
                    document.addEventListener('click', pageClick, { capture: true });
                  }}
                >
                  <PlusIcon />
                </Button>
              </div>
            </div>
            <div className={classes.list}>
              {snippets.map((snippet) => (
                <div
                  className={cn(classes.tag, { [classes.tagSelected]: snippet.name === displayedTag.name })}
                  key={snippet.name}
                  onClick={() => {
                    setDisplayedTag({ name: snippet.name, snippet: nodesTreeToCode(codeToNodesTree(snippet.snippet)), new: false });
                    document.addEventListener('click', pageClick, { capture: true });
                  }}
                >
                  <EyeIcon className={classes.tagIcon} />
                  <span className={classes.tagName}>{snippet.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.buttonsContainer}>
        <Button roundedCorners bgColor={colorsEnum.info} onClick={() => showPreviewSelected()} disabled={selectedTemplate.id === null}>
          Preview selected template
        </Button>
        <Button roundedCorners onClick={() => showPreviewAll()}>Preview all templates</Button>
        <Button roundedCorners bgColor={colorsEnum.success} onClick={updateTemplate} disabled={!selectedTemplateIsChanged}>Save</Button>
      </div>

      {(displayedTag.name !== null) && (
        <div className={classes.tagPopup}>
          {displayedTag.new ? (
            <div className={classes.tagPopupNameContainer}>
              <span className={classes.tagPopupSubtitle}>Name</span>
              <input
                className={classes.tagPopupNameInput}
                onChange={(event) => {
                  event.persist();
                  setDisplayedTag((prevValue) => ({ ...prevValue, name: event.target.value }));
                }}
              />
            </div>
          ) : (
            <p className={classes.tagPopupTitle}>{displayedTag.name}</p>
          )}
          <p className={classes.tagPopupSubtitle}>Value</p>
          <div className={classes.tagPopupContent}>
            {
              (displayedTag.snippet !== null)
                ? (
                  <textarea
                    // ref={textareaDOMNode}
                    className={classes.textarea}
                    value={displayedTag.snippet}
                    onChange={(event) => {
                      event.persist();
                      setDisplayedTag((prevValue) => ({ ...prevValue, snippet: event.target.value }));
                      // autoGrowTextarea();
                    }}
                  />
                )
                : (
                  <pre>
                    {
                      JSON.stringify(emailTemplatesContextTags[
                        Object.keys(emailTemplatesContextTags).find((tagKey) => tagKey === displayedTag.name)
                      ], null, 2)
                    }
                  </pre>
                )
            }
          </div>

          {(displayedTag.snippet !== null) && (
            <div className={classes.tagPopupButtonsContainer}>
              {displayedTag.new ? (
                <Button
                  roundedCorners
                  bgColor={colorsEnum.success}
                  onClick={() => {
                    createSnippet();
                    setDisplayedTag({ name: null, snippet: null, new: null });
                  }}
                  disabled={(displayedTag.name === '') || (displayedTag.snippet === '') || !!snippets.find((s) => s.name === displayedTag.name)}
                >
                  Create snippet
                </Button>
              ) : (
                <>
                  <Button
                    roundedCorners
                    bgColor={colorsEnum.info}
                    onClick={discardSnippetChanges}
                    disabled={!selectedSnippetIsChanged}
                  >
                    Discard changes
                  </Button>
                  <Button
                    roundedCorners
                    bgColor={colorsEnum.success}
                    onClick={updateSnippet}
                    disabled={!selectedSnippetIsChanged}
                  >
                    Save changes
                  </Button>
                  <Button
                    roundedCorners
                    bgColor={colorsEnum.danger}
                    onClick={() => {
                      deleteSnippet();
                      setDisplayedTag({ name: null, snippet: null, new: null });
                    }}
                  >
                    Delete snippet
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MailTemplates;
