import React, {useEffect, useState} from 'react';
import {useMutation, useQuery, gql} from "@apollo/client";
import FormLabel from "@material-ui/core/FormLabel";
// material-ui components
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "../CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-pro-react/modalStyle.js";
import Contacts from "@material-ui/icons/Contacts";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import CustomInput from "../CustomInput/CustomInput";
import CardIcon from "../Card/CardIcon";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const UserModal = ({handleClose, user}) => {

    const classes = useStyles()

    const GET_USER = gql`
        query User($username: String){
            getUser(username: $username){
                id
                username
                firstName
                lastName
                username
                email
            }
        }
    `

    const UPDATE_USER_MUTATION = gql`
        mutation UpdateUser($username: String!, $firstName: String, $lastName: String){
            setUserRealName(username: $username, firstName: $firstName, lastName: $lastName){
                id
                firstName
                lastName
                name
            }
        }
    `

    const UPDATE_PASSWORD_MUTATION = gql`
        mutation SetPassword($username: String!, $passwd: String!){
            changePassword(username: $username, password: $passwd)
        }
    `

    const CHANGE_EMAIL = gql`
        mutation ChangeEmail($oldEmail: String!, $newEmail: String!){
            setUserEmail(oldEmail: $oldEmail, newEmail: $newEmail){
                id
                name
                username
                email
            }
        }
    `


    const [updateUser, {error: userUpdateError}] = useMutation(UPDATE_USER_MUTATION);
    const [updatePassword, {error: passwordUpdateError}] = useMutation(UPDATE_PASSWORD_MUTATION);
    const [changeEmail, {error: changeEmailError}] = useMutation(CHANGE_EMAIL);


    let username = user.username
    const {loading, error, data} = useQuery(GET_USER, {
        variables: {username}
    });

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")

    useEffect(() => {
        if (!loading && !error) {
            setLastName(data.getUser.lastName)
            setFirstName(data.getUser.firstName)
            setEmail(data.getUser.email)
        }
    }, [loading, error, data])

    const handleSave = () => {
        updateUser(
            {variables: {username: username, firstName: firstName, lastName: lastName}}
        )
        if (password.length > 0)
            updatePassword(
                {variables: {username: username, passwd: password}}
            )

        if(email != data.getUser.email){
            changeEmail(
                {variables: {oldEmail: data.getUser.email, newEmail: email}}
            )
        }
        handleClose()
    }

    const handleKeyPress = (target) => {
        let e = target.nativeEvent
        if (e.charCode === 13 || e.code === 'Enter' || e.code === 'NumpadEnter')
            handleSave()
    }

    if (loading)
        return <tr>
            <td colSpan={5}>Loading...</td>
        </tr>

    if (error || userUpdateError || passwordUpdateError)
        return (<tr>
            <td colSpan={5}>Error</td>
        </tr>)

    console.log(data.getUser)

    return (
        <div>
            <Dialog
                fullScreen={true}
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={true}
                transition={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={handleClose}
                    >
                        <Close className={classes.modalClose}/>
                    </Button>
                    <h4 className={classes.modalTitle}>User</h4>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}
                >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <Contacts/>
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>User</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Username
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={9}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    autoComplete: "off",
                                                    value: data.getUser.username,
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                First name
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={9}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    autoComplete: "off",
                                                    value: firstName,
                                                    onChange: event => {
                                                        setFirstName(event.target.value)
                                                    },
                                                    onKeyDown: e => {
                                                        handleKeyPress(e)
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Last name
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={9}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    autoComplete: "off",
                                                    value: lastName,
                                                    onChange: event => {
                                                        setLastName(event.target.value)
                                                    },
                                                    onKeyDown: e => {
                                                        handleKeyPress(e)
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Email
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={9}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    autoComplete: "off",
                                                    value: email,
                                                    onChange: event => {
                                                        setEmail(event.target.value)
                                                    },
                                                    onKeyDown: e => {
                                                        handleKeyPress(e)
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Password
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={9}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "password",
                                                    autoComplete: "off",
                                                    value: password,
                                                    onChange: event => {
                                                        setPassword(event.target.value)
                                                    },
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <Button onClick={handleClose} color="info">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="success">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default UserModal
