import { colors, shadows } from '@collabra/cway-frontend-common/constants';

const styles = {
  wrapper: {
    display: 'inline-block',
  },
  wrapperSpaced: {
    '& + &': {
      marginLeft: 5,  // spacing before all wrapper instances except the first one
    },
  },
  wrapperFullWidth: {
    width: '100%',
  },

  button: {
    cursor: 'pointer',
    border: 'none',
    width: '100%',
    height: 'auto',
    minWidth: 'auto',
    minHeight: 'auto',
    padding: 3,
    lineHeight: 1,
    fontSize: 'inherit',          // button content size can be adjusted by inheriting parent's fontSize
    fontWeight: 400,
    '&:disabled': {
      backgroundColor: colors.transparent,
      color: colors.gray,
    },
    overflow: 'hidden',
  },
  textButton: {
    border: `1px solid ${colors.gray}`,
    borderRadius: 0,
    padding: '6px 8px',
    textTransform: 'unset',           // overwrite material-ui setting
    color: colors.white,
  },
  iconButton: {
    borderRadius: '50%',
    backgroundColor: colors.transparent,
    color: colors.text,
  },

  active: {
    color: `${colors.info} !important`,
  },
  roundedCorners: {
    borderRadius: 6,
  },

  transparent: {
    backgroundColor: colors.transparent,
    color: colors.text,
    '&:hover': {
      backgroundColor: colors.transparent,
      boxShadow: shadows.gray,
      color: colors.text,
    },
  },
  primary: {
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.primary,
      boxShadow: shadows.primary,
    },
  },
  info: {
    backgroundColor: colors.info,
    '&:hover': {
      backgroundColor: colors.info,
      boxShadow: shadows.info,
    },
  },
  success: {
    backgroundColor: colors.success,
    '&:hover': {
      backgroundColor: colors.success,
      boxShadow: shadows.success,
    },
  },
  warning: {
    backgroundColor: colors.warning,
    '&:hover': {
      backgroundColor: colors.warning,
      boxShadow: shadows.warning,
    },
  },
  danger: {
    backgroundColor: colors.danger,
    '&:hover': {
      backgroundColor: colors.danger,
      boxShadow: shadows.danger,
    },
  },
  rose: {
    backgroundColor: colors.rose,
    '&:hover': {
      backgroundColor: colors.rose,
      boxShadow: shadows.rose,
    },
  },
  white: {
    backgroundColor: colors.white,
    color: colors.text,
    '&:hover': {
      backgroundColor: colors.white,
      boxShadow: shadows.gray,
      color: colors.text,
    },
  },
};

export default styles;
