// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';

// Child components
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import Contacts from '@material-ui/icons/Contacts';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../CustomButtons/Button';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CardBody from '../Card/CardBody';
import CustomInput from '../CustomInput/CustomInput';
import Card from '../Card/Card';

// Utils
import { isValidEmail } from '@collabra/cway-frontend-common/utils';

// Styling
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/material-dashboard-pro-react/modalStyle.js';
const useStyles = makeStyles(styles);

const ADD_USER_TO_ORG = gql`
    mutation CreateUser($input: UserInput!){
        createUser(input: $input) {
            id
            organisationMemberships {
                organisationId
            }
        }
    }
`;

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const UserInviteDialog = ({ organisation, handleClose, reloadOrg }) => {
  const classes = useStyles();

  const [username, setUsername] = useState('');

  const usernameIsValidAndNew = isValidEmail(username) && !organisation.users.find((u) => u.username === username);
  console.log({ usernameIsValidAndNew });

  const [addUser] = useMutation(ADD_USER_TO_ORG);
  const handleAddUser = () => {
    addUser({ variables: { input: { organisationId: organisation.id, username } } });
    reloadOrg();
    handleClose();
  };
  const handleKeyPress = (target) => {
    const e = target.nativeEvent;
    if (e.charCode === 13 || e.code === 'Enter' || e.code === 'NumpadEnter') {
      handleAddUser();
    }
  };

  return (
    <Dialog
      classes={{ root: classes.center, paper: classes.modal }}
      open
      transition={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
        <Button justIcon className={classes.modalCloseButton} color="transparent" onClick={handleClose}>
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Invite user to organisation</h4>
      </DialogTitle>

      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Organisation</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Email
                    </FormLabel>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        autoComplete: 'off',
                        value: username,
                        onChange: (event) => setUsername(event.target.value),
                        onKeyDown: handleKeyPress,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Button onClick={handleAddUser} disabled={!usernameIsValidAndNew} color="danger">Invite</Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

UserInviteDialog.propTypes = {
  organisation: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadOrg: PropTypes.func.isRequired,
};

export default UserInviteDialog;
