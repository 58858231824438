// Packages
import React, { useState } from 'react';

// Child components
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Organisations from './Organisations';
import Users from './Users';

const OrganisationsPage = () => {
  const [selectedOrganisations, setSelectedOrganisations] = useState([]);
  const [usersShown, setUsersShown] = useState(null);

  return (
    <GridContainer>
      <GridItem sm={1} />
      <GridItem sm={10}>
        {usersShown ? (
          <Users organisation={usersShown} showOrgs={() => setUsersShown(null)} />
        ) : (
          <Organisations
            selectedOrganisations={selectedOrganisations}
            setSelectedOrganisations={setSelectedOrganisations}
            showUsers={(organisation) => setUsersShown(organisation)}
          />
        )}
      </GridItem>
    </GridContainer>
  );
};

export default OrganisationsPage;
