// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

const SelectBox = ({ item, selectedItems, select, deselect }) => (
  selectedItems.includes(item)
    ? <FontAwesomeIcon icon={faCheckCircle} onClick={() => deselect(item)} />
    : <FontAwesomeIcon icon={faCircle} onClick={() => select(item)} />
);

SelectBox.propTypes = {
  item: PropTypes.any.isRequired,
  selectedItems: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired,
  deselect: PropTypes.func.isRequired,
};

export default SelectBox;
