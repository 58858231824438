import UserList from "./UserList";
import FilterBox from "./FilterBox";
import React, {useState} from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";


const UserPage = () => {
    const [username, filterUsername] = useState("")

    return (<GridContainer>
        <GridItem sm={1} />
        <GridItem sm={10}>
        <FilterBox filterUsername={filterUsername}/>
        <UserList username={username}/>
        </GridItem>
    </GridContainer>)

}

export default UserPage

