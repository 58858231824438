import React, {useState} from "react";
import {useQuery, gql} from "@apollo/client";
import {Redirect} from "react-router-dom";

const LOGIN_INFO = gql`
    query LoginInfo {
        loginInfo {
            user {
                id
                username
                firstName
                lastName
                name
                email
            }
            permissions{
                userAdministration
            }
        }
    }
`;

const LoginInfo = ({setLoginInfo}) => {

    const {loading, error, data} = useQuery(LOGIN_INFO, {
        pollInterval: 5000,
    });

    if (loading)
        return <div></div>

    if (error || !data.loginInfo || !data.loginInfo.permissions.userAdministration)
        return <Redirect to="/admin/auth/login-page"/>

    setLoginInfo(data.loginInfo)

    console.log(data)
    return <div></div>
}

export default LoginInfo
