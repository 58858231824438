import React, {useEffect, useState} from 'react';
import {useMutation, useQuery, gql} from "@apollo/client";
// material-ui components
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "../CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-pro-react/modalStyle.js";
import Contacts from "@material-ui/icons/Contacts";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import CardIcon from "../Card/CardIcon";
import Table from "../Table/Table.js";
import {Label, LabelRounded} from "@material-ui/icons";
import Badge from "../Badge/Badge";
import PermissionGroupField from "./PermissionGroupField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const UserOrganisations = ({handleClose, user}) => {

    const classes = useStyles()

    const GET_USER = gql`
        query User($username: String){
            getUser(username: $username){
                id
                username
                firstName
                lastName
                organisationMemberships{
                    organisationId
                    name
                    permissionGroupId
                }
            }
        }
    `
    const UPDATE_USER_MUTATION = gql`
        mutation UpdateUser($username: String!, $firstName: String, $lastName: String){
            setUserRealName(username: $username, firstName: $firstName, lastName: $lastName){
                id
                firstName
                lastName
                name
            }
        }
    `

    const [updateUser, {error: userUpdateError}] = useMutation(UPDATE_USER_MUTATION);

    let username = user.username
    const {loading, error, data} = useQuery(GET_USER, {
        variables: {username},
    });

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    useEffect(() => {
        if (!loading && !error) {
            setLastName(data.getUser.lastName)
            setFirstName(data.getUser.firstName)
        }
    }, [loading, error, data])

    const handleSave = () => {
        updateUser(
            {variables: {username: username, firstName: firstName, lastName: lastName}}
        )
        handleClose()
    }

    const handleKeyPress = (target) => {
        let e = target.nativeEvent
        if (e.charCode === 13 || e.code === 'Enter' || e.code === 'NumpadEnter')
            handleSave()
    }

    if (loading)
        return <tr>
            <td colSpan={5}>Loading...</td>
        </tr>

    if (error || userUpdateError)
        return (<tr>
            <td colSpan={5}>Error</td>
        </tr>)

    const tableData = () => {
        let rows = []
        if (data && data.getUser)
            rows = data.getUser.organisationMemberships.map(org => [
                    <Badge color="rose">{org.name}</Badge>,
                    //<PermissionGroupField organisationId={org.organisationId} username={user.username} permissionGroupId={org.permissionGroupId} />,
                ],
            )
        if (rows.length > 0)
            return rows
        else
            return [["No organisations found"]]
    }

    return (
        <div>
            <Dialog
                fullScreen={false}
                scroll={"body"}
                classes={{
                    root: classes.center,
                    paper: classes.modal,
                }}
                open={true}
                transition={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={handleClose}
                    >
                        <Close className={classes.modalClose}/>
                    </Button>
                    <h4 className={classes.modalTitle}>User</h4>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}
                >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <Contacts/>
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>Organisation memberships</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <Table
                                            tableHeaderColor="primary"
                                            //tableHead={["Name", "Permission group"]}
                                            tableHead={["Organisations"]}
                                            tableData={tableData()}
                                            coloredColls={[3]}
                                            colorsColls={["primary"]}
                                        />
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <Button color="info" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default UserOrganisations
