import Dashboard from "views/Dashboard.js";
import LoginPage from "views/LoginPage.js";
import MailTemplates from 'views/MailTemplates';
import MailTemplatesTabs from 'views/MailTemplatesTabs';

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonSharp from "@material-ui/icons/PersonSharp";
import Mail from "@material-ui/icons/Mail";
import UserPage from "./components/UserPage/UserPage";
import LogoutPage from "./views/LogoutPage";
import StoragePage from "./components/Storage/StoragePage";
import StorageTwoTone from "@material-ui/icons/StorageTwoTone";
import OrganisationPage from "./components/Organisations/OrganisationsPage";
import {BusinessTwoTone} from "@material-ui/icons";

let dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "الحاجيات",
    icon: PersonSharp,
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/storagePage",
    name: "Storage",
    icon: StorageTwoTone,
    component: StoragePage,
    layout: "/admin",
  },
  {
    path: "/organisations",
    name: "Organisations",
    icon: BusinessTwoTone,
    component: OrganisationPage,
    layout: "/admin",
  },
  {
    path: "/login-page",
    name: "Login Page",
    mini: "L",
    component: LoginPage,
    layout: "/admin/auth",
  },
  {
    path: "/logout",
    name: "Logout Page",
    mini: "LO",
    component: LogoutPage,
    layout: "/admin/auth",
  },
];
export default dashRoutes;
