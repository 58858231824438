// @ts-nocheck
/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { createRoot } from 'react-dom/client';
import {createBrowserHistory} from 'history';
import {Router, Route, Switch, Redirect} from 'react-router-dom';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';
import 'assets/css/material-dashboard-pro-react.css';
import {CookiesProvider} from 'react-cookie';
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@collabra/cway-frontend-common/components';

// GraphQL client
import ApolloClient from './graphql/Apollo';

const hist = createBrowserHistory();

const root = createRoot(document.getElementById('root')!);
root.render(
    <ApolloProvider client={ApolloClient}>
        <CookiesProvider>
            <ErrorBoundary>
                <Router history={hist}>
                    <Switch>
                        <Route path="/admin/auth" component={AuthLayout}/>
                        <Route path="/admin" component={AdminLayout}/>
                        <Redirect from="/" to="/admin/dashboard"/>
                        <Redirect from="/admin" to="/admin/dashboard"/>
                        <Redirect from="/admin/" to="/admin/dashboard"/>
                    </Switch>
                </Router>
            </ErrorBoundary>
        </CookiesProvider>
    </ApolloProvider>,
);
