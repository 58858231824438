import React from 'react';
import {useQuery, gql} from "@apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import Table from "../Table/Table.js";
import StorageTwoTone from "@material-ui/icons/StorageTwoTone";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import CardBody from "../Card/CardBody";
import {makeStyles} from "@material-ui/core/styles";
import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import Info from "../Typography/Info";

const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

const StorageList = () => {
    const classes = useStyles();

    const STORAGE_QUERY = gql`
        query Storage{
            mediaCenterStorageForAllOrganisations(unit: GIGABYTES){
                organisationId
                organisationName
                totalSize
                fullSize
                unit
            }
        }
    `
    const {loading, error, data} = useQuery(STORAGE_QUERY)

    if (error) return (<p>Error :(</p>)

    const tableData = () => {
        let rows = []
        if (data && data.mediaCenterStorageForAllOrganisations)
            rows = data.mediaCenterStorageForAllOrganisations.map(organisation =>
            [organisation.organisationName, <div><em>{organisation.totalSize}</em> GB</div>, <div><em>{organisation.fullSize}</em> GB</div>]
            )
        return rows
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <StorageTwoTone />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Storage in mediacenter</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Organisation", "Uploaded Size", "Full size"]}
                            tableData={tableData()}
                            coloredColls={[3]}
                            colorsColls={["primary"]}
                        />
                        {loading &&
                        <Info><FontAwesomeIcon icon={faSpinner} spin={true}/> <strong>Loading..</strong></Info>}
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>)

}

export default StorageList

