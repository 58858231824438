import React, {useState} from 'react';
import {useQuery, gql} from "@apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSpinner, faClipboard, faUser} from "@fortawesome/free-solid-svg-icons";
import Table from "../Table/Table.js";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import CardBody from "../Card/CardBody";
import {makeStyles} from "@material-ui/core/styles";
import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import Button from "@material-ui/core/Button";
import UserModal from "./UserModal";
import Info from "../Typography/Info";
import UserOrganisations from "./UserOrganisations";
import CopyPopup from "./CopyPopup";
import UserPage from "./UserPage";

const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
};

const useStyles = makeStyles(styles);

const UserModalButton = ({user}) => {
    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false);

    if (showModal)
        return <UserModal user={user} handleClose={handleClose}/>
    return <Button onClick={() => setShowModal(true)}>Edit</Button>
}

const UserOrganisationsButton = ({user}) => {
    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false);

    if (showModal)
        return <UserOrganisations user={user} handleClose={handleClose}/>
    return <Button onClick={() => setShowModal(true)}>Org</Button>
}

const UserIdColumn = ({user, showPopup}) => {
    const copyId = (id) => {
        navigator.clipboard.writeText(id);
        showPopup()
    };

    return (<div>
        <FontAwesomeIcon icon={faClipboard} onClick={() => copyId(user.id)} size="lg"/>&nbsp; {user.id}
    </div>);
};

const UserList = ({username}) => {
    const classes = useStyles();
    const [showPopup, setShowPopup] = useState(false)

    const search = gql`
        query Users($username: String){
            findUsersInAnyOrganisation(username: $username){
                id
                username
                name
                enabled
            }
        }
    `

    const {loading, error, data} = useQuery(search, {
        variables: {
            username: username,
        },
        //pollInterval: 30000,
        notifyOnNetworkStatusChange: true
    });

    const MAX_NUMBER_OF_USERS_TO_SHOW = 100;

    const showCopyPopup = () => {
        setShowPopup(true)
        setTimeout(() => setShowPopup(false), 1000)
    };

    if (error) return (<p>Error :(</p>)

    console.log(data)

    const tableData = () => {
        let rows = []
        if (data && data.findUsersInAnyOrganisation)
            rows = [rows, ...data.findUsersInAnyOrganisation.slice(0, MAX_NUMBER_OF_USERS_TO_SHOW).map(user =>
                [
                    <UserIdColumn user={user} showPopup={showCopyPopup}/> ,
                    user.username, user.name, user.enabled ? <FontAwesomeIcon icon={faCheck}/> : null,
                    <div><UserModalButton user={user}>Edit</UserModalButton><UserOrganisationsButton user={user}>Org</UserOrganisationsButton></div>
                ],
            )]
        return rows
    }

    return (
        <GridContainer>
            {showPopup && <CopyPopup/>}
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <FontAwesomeIcon icon={faUser}/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Users</h4>
                    </CardHeader>
                    <CardBody>
                        <small>* maximum of {MAX_NUMBER_OF_USERS_TO_SHOW} users shown. Type in search to narrow
                            selection.</small>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Id", "Username", "Name", "Enabled", ""]}
                            tableData={tableData()}
                            coloredColls={[3]}
                            colorsColls={["primary"]}
                        />
                        {loading
                        && <Info><FontAwesomeIcon icon={faSpinner} spin={true}/> <strong>Loading...</strong></Info>}
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default UserList
