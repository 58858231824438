// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';

// Child components
import Table from '../Table/Table.js';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CardBody from '../Card/CardBody';
import Info from '../Typography/Info';
import OrganisationToolBar from './OrganisationsToolBar';
import SelectBox from './SelectBox';

// Icons
import { faCheck, faCheckCircle, faCircle, faSpinner, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BusinessTwoTone } from '@material-ui/icons';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import Button from "../CustomButtons/Button";
const styles = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};
const useStyles = makeStyles(styles);

// GraphQL queries
const ORGANISATIONS = gql`
    query Organisations {
        organisations {
            id
            name
            active
        }
    }
`;

const Organisations = ({ selectedOrganisations, setSelectedOrganisations, showUsers }) => {
  console.group('Organisations()');
  const classes = useStyles();

  // ---------- Get organisations list --------------------

  const { loading, error, data } = useQuery(ORGANISATIONS, { pollInterval: 1000 });
  const allOrgs = data?.organisations;

  // ---------- Select organisations --------------------

  const selectRow = (organisation) => {
    if (!selectedOrganisations.includes(organisation)) setSelectedOrganisations(selectedOrganisations.concat(organisation));
  };
  const deselectRow = (organisation) => {
    if (selectedOrganisations.includes(organisation)) setSelectedOrganisations(selectedOrganisations.filter((item) => item !== organisation));
  };
  const deselectAllOrgs = () => setSelectedOrganisations([]);
  const selectAllOrgs = () => setSelectedOrganisations(allOrgs);

  // ---------- Data for table --------------------

  const orgsTableData = () => {
    let rows = [];
    if (allOrgs) {
      rows = allOrgs.map((organisation) => ([
        <SelectBox item={organisation} selectedItems={selectedOrganisations} select={selectRow} deselect={deselectRow} />,
        organisation.name,
        organisation.active ? <FontAwesomeIcon icon={faCheck} /> : <div />,
          <Button disabled={false} onClick={() => showUsers(organisation)} color="info">
              <FontAwesomeIcon icon={faUsers} />
          </Button>,
      ]));
    }
    return rows;
  };

  // ---------- GraphQL error --------------------

  if (error) return (<p>Error :(</p>);

  // --------------------------------------------------------------------------------

  console.groupEnd();

  return (
    <div>
      <OrganisationToolBar selectedOrganisations={selectedOrganisations} deselectAll={deselectAllOrgs} />

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose"><BusinessTwoTone /></CardIcon>
              <h4 className={classes.cardIconTitle}>Organisations</h4>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  selectedOrganisations.length > 0
                    ? <FontAwesomeIcon icon={faCheckCircle} onClick={deselectAllOrgs} />
                    : <FontAwesomeIcon icon={faCircle} onClick={selectAllOrgs} />,
                  'Name',
                  'Active',
                  'Manage',
                ]}
                tableData={orgsTableData()}
                coloredColls={[4]}
                colorsColls={['primary', 'info', 'info', 'info']}
              />
              {loading && <Info><FontAwesomeIcon icon={faSpinner} spin /> <strong>Loading..</strong></Info>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

Organisations.propTypes = {
  selectedOrganisations: PropTypes.array.isRequired,
  setSelectedOrganisations: PropTypes.func.isRequired,
  showUsers: PropTypes.func.isRequired,
};

export default Organisations;
